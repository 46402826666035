export const menuEnum = {
  home: {
    name: "Home",
    id: 1,
    key: "homeIndex",
    label: "menu1",
    path: "/home",
    icons: "",
  },
  companyVision: {
    name: "companyVision",
    id: 2,
    key: "companyVision",
    label: "menu2",
    path: "/companyVision",
    icons: "",
  },
  contactUs: {
    name: "contactUs",
    id: 3,
    key: "contactUs",
    label: "menu3",
    path: "/contactUs",
    icons: "",
  },
};
