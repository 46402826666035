import Vue from "vue";
import App from "./App.vue";
import enLocale from "../languages/en.json";
import zhLocale from "../languages/zh.json";
import VueI18n from "vue-i18n";
import config from "@/assets/js/config";
import { Carousel, CarouselItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;
let _channel = Number(process.env.VUE_APP_WEBSITE_TYPE);

let { htmlTitle } = config;
document.title = htmlTitle[_channel];
Vue.use(VueI18n);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
const messages = {
  en: enLocale,
  zh: zhLocale,
};

const i18n = new VueI18n({
  locale: "en", // 默认语言
  messages,
});

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount("#app");
