<template>
  <div id="app" @wheel="(e) => e.preventDefault()">
    <header-menu
      @changeTab="changeTab"
      :menuEnum="menuEnum"
      :activeRoute="activeRoute"
      :loop="false"
    ></header-menu>
    <div class="box" @mousedown="recordStard" @mouseup="recordEnd">
      <el-carousel
        ref="ElcarouselRef"
        indicator-position="outside"
        @change="refreshRoute"
        :autoplay="false"
        arrow="never"
      >
        <el-carousel-item v-for="(value, key) in menuEnum" :key="key">
          <component :is="value.key" @gotoContactUs="gotoContactUs"></component>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import { menuEnum } from "./assets/js/menuEnum";
import headerMenu from "@/components/headerMenu.vue";
import homeIndex from "./components/homeIndex.vue";
import companyVision from "./components/companyVision.vue";
import contactUs from "./components/contactUs.vue";

export default {
  name: "App",
  components: {
    headerMenu,
    homeIndex,
    companyVision,
    contactUs,
  },
  computed: {
    menuEnum() {
      return menuEnum;
    },
  },
  data() {
    return {
      activeRoute: 1,
      isCtrlPressed: false,
      isShiftPressed: false,
      timer: null,
      startPoint: null,
    };
  },
  methods: {
    changeTab(routeId) {
      this.activeRoute = routeId;
      this.$refs.ElcarouselRef.setActiveItem(this.activeRoute - 1);
    },
    gotoContactUs() {
      this.activeRoute = this.menuEnum.contactUs.id;
      this.$refs.ElcarouselRef.setActiveItem(this.activeRoute - 1);
    },
    refreshRoute(e) {
      this.activeRoute = e + 1;
    },
    handleKeydown(e) {
      if (e.key === "Control") {
        this.isCtrlPressed = true;
      } else if (e.key === "Shift") {
        this.isShiftPressed = true;
      }
    },
    handleKeyup(e) {
      if (e.key === "Control") {
        this.isCtrlPressed = false;
      } else if (e.key === "Shift") {
        this.isShiftPressed = false;
      }
    },
    handleWhell(e) {
      if (this.timer) return;
      console.log(e,'滚轮事件');
      
      if (e.deltaY > 0) {
        this.$refs.ElcarouselRef.next();
      } else {
        this.$refs.ElcarouselRef.prev();
      }
      let delay = Math.abs(e.deltaY) === 100 ? 500 : 1000;
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
      }, delay);
    },
    recordStard(e) {
      this.startPoint = {
        x: e.clientX,
        y: e.clientY,
      };
    },
    recordEnd(e) {
      if (!this.recordStard) return;
      let direct = e.clientX - this.startPoint.x;
      direct > 0
        ? this.$refs.ElcarouselRef.prev()
        : this.$refs.ElcarouselRef.next();
      this.startPoint = null;
    },
    throttle(func, delay, immediate) {
      //func为需要节流的函数，delay为节流的时间，immediate为是否需要先执行
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        if (timer) clearTimeout(timer);
        if (immediate) {
          //根据距离上次触发操作的时间是否到达delay来决定是否要现在执行函数
          let doNow = !timer;
          //每一次都重新设置timer，就是要保证每一次执行的至少delay秒后才可以执行
          timer = setTimeout(function () {
            timer = null;
          }, delay);
          //立即执行
          if (doNow) {
            func.apply(context, args);
          }
        } else {
          timer = setTimeout(function () {
            func.apply(context, args);
          }, delay);
        }
      };
    },
  },
  mounted() {
    addEventListener("wheel", this.handleWhell);
    addEventListener("keydown", this.handleKeydown);
    addEventListener("keyup", this.handleKeyup);
  },
  beforeDestroy() {
    removeEventListener("wheel", this.handleWhell);
    removeEventListener("keydown", this.handleKeydown);
    removeEventListener("keyup", this.handleKeyup);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  user-select: none;
}
@import "./assets/css/common.scss";
@import "./assets/css/index.scss";
</style>
