<template>
  <div class="container">
    <div class="inner-container">
      <div class="title">{{ $t("menu2") }}</div>
      <div class="content">
        <div v-for="item in visionList" :key="item.title" class="item">
          <div class="img-container">
            <img :src="require(`../assets/img/${item.imgUrl}.webp`)" alt="" />
          </div>
          <div class="vision-title">{{ $t(`${item.title}`) }}</div>
          <div class="vision-descript">{{ $t(`${item.desc}`) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visionList: [
        {
          imgUrl: "vision_1",
          title: "textblack21",
          desc: "textdesc21",
        },
        {
          imgUrl: "vision_2",
          title: "textblack22",
          desc: "textdesc22",
        },
        {
          imgUrl: "vision_3",
          title: "textblack23",
          desc: "textdesc23",
        },
        {
          imgUrl: "vision_4",
          title: "textblack24",
          desc: "textdesc24",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url("../assets/img/bg_vision.webp") no-repeat;
  background-size: 100vw 20%;

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 120px;
    .title {
      font-size: 40px;
      font-weight: 700;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 45%;
        z-index: -1;
        bottom: 0px;
        width: 170px;
        height: 15px;
        background: #c4dcfe;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      .item {
        width: 25%;
        margin-right: 36px;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        color: #333;
        background: #f3f7ff;
        border-radius: 16px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
        &:last-child {
          margin-right: 0px;
        }
        .vision-title {
          font-weight: 700;
        }
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .item {
      padding: 48px 0px;
    }
    .vision-title {
      font-size: 24px;
    }
    .vision-descript {
      font-size: 16px;
      line-height: 32px;
    }
    .img-container {
      width: 216px;
      height: 216px;
    }
    .vision-title {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .item {
    padding: 24px 0px;
  }
  .vision-title {
    font-size: 18px;
  }
  .vision-descript {
    font-size: 12px;
    line-height: 24px;
  }
  .img-container {
      width: 80%;
    }
  .vision-title {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}
</style>