<template>
  <div class="container">
    <div class="logo-container">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="menu-container">
      <div class="menu-item" v-for="(value, key) in menuEnum" :key="key">
        <span
          :class="[activeRoute === value.id ? 'activeRoute' : '']"
          @click="handleClick(value.id)"
          >{{ $t(`${value.label}`) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuEnum: {
      type: Object,
      default: () => {},
    },
    activeRoute: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleClick(id) {
      this.$emit("changeTab", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 110px;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  .logo-container,
  .menu-container {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
  }
  .logo-container {
    height: 56px;
  }
  .menu-container {
    justify-content: space-around;
    .menu-item {
      width: 33%;
      font-size: 16px;
      font-weight: 700;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        color: rgba(8, 135, 226, 0.6);
      }
    }

    .activeRoute {
      color: #0887e2;
    }
  }
}

</style>