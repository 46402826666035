<template>
  <div class="container">
    <div class="home-container">
      <div class="title">{{ $t("textblack11") }}</div>
      <div class="descript">{{ 
      $t("textdesc11") }}</div>
      <button class="default-button" @click="contactUs">{{ $t("menu3") }}</button>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    contactUs() {
      this.$emit('gotoContactUs')
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  background: url("../assets/img/bg_index.webp") no-repeat;
  background-size: cover;
  .home-container {
    width: 720px;
    text-align: left;
    .title {
      font-size: 60px;
      font-weight: 700;
    }
    .descript {
        font-size: 32px;
        color: #666;
        margin-top: 24px;
    }
    .default-button {
        margin-top: 48px;
        padding: 16px 48px;
        border-radius: 8px;
        transition: .2s;
        background: #0887e2;
        color: #fff;
        font-size: 24px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
            background: rgba(8, 135, 226,.6);
        }
    }
  }
}


</style>