<template>
  <div class="container">
    <div class="content">
      <div class="img-container">
        <img src="../assets/img/contact_1.webp" alt="" />
      </div>
      <div class="contact-content">
        <div class="title">{{ $t("menu3") }}</div>
        <div class="contact-list">
          <div
            class="contact-item"
            v-for="item in contactMethods"
            :key="item.text"
          >
            <div class="icon">
              <img
                :src="require(`../assets/img/${item.icon}.webp`)"
                alt=""
              />
            </div>
            <div class="text">{{ $t(`${item.text}`) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactMethods: [
        { icon: "icon_gongsi", text: "company" },
        { icon: "icon_dizhi", text: "componyaddress" },
        { icon: "icon_youxiang", text: "email" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .content {
    display: flex;
    align-items: center;
    .img-container {
      width: 667px;
      height: 444px;
      margin-right: 60px;
      position: relative;
    }
    .contact-content {
      flex: 1;
      width: 100%;
      text-align: left;
      .title {
        font-size: 46px;
        font-weight: 700;
        position: relative;
        margin-bottom: 36px;
        &::after {
          content: "";
          position: absolute;
          left: 40px;
          z-index: -1;
          bottom: 5px;
          width: 170px;
          height: 15px;
          background: #c4dcfe;
        }
      }
      .contact-list {
        .contact-item {
          display: flex;
          line-height: 28px;
          align-items: center;
          margin-bottom: 24px;
          font-size: 24px;
          .icon {
            margin-right: 16px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
</style>